import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as auth from '../app/modules/Auth/_redux/authRedux'
import { announcementSlice } from '../app/modules/Announcements/_redux/announcements/announcementSlice'
import { meetingSettingsSlice } from '../app/modules/MeetingSettings/_redux/meetingSettings/meetingSettingsSlice'
import { schedulesSlice } from '../app/modules/Schedule/_redux/schedules/schedulesSlice'
import { settingsSlice } from '../app/modules/Setting/_redux/settings/settingsSlice'
import { coursesSlice } from '../app/modules/Course/_redux/courses/coursesSlice'

export const rootReducer = combineReducers({
	auth: auth.reducer,
	announcements: announcementSlice.reducer,
	meetingSettings: meetingSettingsSlice.reducer,
	schedules: schedulesSlice.reducer,
	settings: settingsSlice.reducer,
	courses: coursesSlice.reducer,
})

export function* rootSaga() {
	yield all([auth.saga()])
}
