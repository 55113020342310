import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialAnnouncementState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const announcementSlice = createSlice({
  name: "announcement",
  initialState: initialAnnouncementState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    announcementsPreviewFetched: (state, action) => {
      const { totalCount, entities, r } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      if (r === true) toast.success("Acknowledged!...");
    }
  }
});
