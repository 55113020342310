import React, { Suspense, lazy } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout'
import { useSelector, shallowEqual } from 'react-redux'

const SchedulesPage = lazy(() =>
	import('./modules/Schedule/pages/schedules/SchedulesPage')
)
const MeetingSettingPage = lazy(() =>
	import('./modules/MeetingSettings/MeetingSettings')
)
const SettingPage = lazy(() => import('./modules/Setting/SettingEditPage'))
const CoursePage = lazy(() =>
	import('./modules/Course/pages/courses/CoursesPage')
)

export default function BasePage() {
	const { role_level } = useSelector(
		(state) => state.auth.user.role_details,
		shallowEqual
	)
	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				<Redirect exact from='/' to='/schedules' />
				<ContentRoute path='/schedules' component={SchedulesPage} />
				{role_level <= 4 ? (
					<ContentRoute path='/courses' component={CoursePage} />
				) : (
					<Redirect to='/schedules' />
				)}
				{role_level <= 1 ? (
					<>
						<ContentRoute
							path='/meeting_settings'
							component={MeetingSettingPage}
						/>
						<ContentRoute path='/settings' component={SettingPage} />
					</>
				) : (
					<Redirect to='/schedules' />
				)}
				<Redirect to='/error' />
			</Switch>
		</Suspense>
	)
}
