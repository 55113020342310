/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'
import { checkIsActive } from '../../../../_helpers'

export function AsideMenuList({ layoutProps }) {
	const { role_level } = useSelector(
		(state) => state.auth.user.role_details,
		shallowEqual
	)
	const location = useLocation()
	const getMenuItemActive = (url, hasSubmenu = false) => {
		return checkIsActive(location, url)
			? ` ${!hasSubmenu &&
					'menu-item-active'} menu-item-open menu-item-not-hightlighted`
			: ''
	}

	return (
		<>
			{/* begin::Menu Nav */}
			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				{/* begin::section */}
				<li className='menu-section '>
					<h4 className='menu-text'>Application</h4>
					<i className='menu-icon flaticon-more-v2'></i>
				</li>
				{/* end:: section */}

				<>
					{/*begin::1 Level*/}
					<li
						className={`menu-item ${getMenuItemActive('/schedules', false)}`}
						aria-haspopup='true'
					>
						<NavLink className='menu-link' to='/schedules'>
							<span className='svg-icon menu-icon'>
								<span className='material-icons'>event</span>
							</span>
							<span className='menu-text'>Schedules</span>
						</NavLink>
					</li>
					{/*end::1 Level*/}

					{role_level <= 4 && (
						<li
							className={`menu-item ${getMenuItemActive(
								'/meeting_settings',
								false
							)}`}
							aria-haspopup='true'
						>
							<NavLink className='menu-link' to='/courses'>
								<span className='svg-icon menu-icon'>
									<span className='material-icons'>list</span>
								</span>
								<span className='menu-text'>Courses</span>
							</NavLink>
						</li>
					)}
					{role_level <= 1 && (
						<>
							<li
								className={`menu-item ${getMenuItemActive(
									'/meeting_settings',
									false
								)}`}
								aria-haspopup='true'
							>
								<NavLink className='menu-link' to='/meeting_settings'>
									<span className='svg-icon menu-icon'>
										<span className='material-icons'>room_preferences</span>
									</span>
									<span className='menu-text'>Meeting Settings</span>
								</NavLink>
							</li>
							<li
								className={`menu-item ${getMenuItemActive('/settings', false)}`}
								aria-haspopup='true'
							>
								<NavLink className='menu-link' to='/settings'>
									<span className='svg-icon menu-icon'>
										<span className='material-icons'>tune</span>
									</span>
									<span className='menu-text'>Settings</span>
								</NavLink>
							</li>
						</>
					)}
				</>
			</ul>
			{/* end::Menu Nav */}
		</>
	)
}
