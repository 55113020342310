import React from 'react'
// import { useField } from 'formik'
import { FieldFeedbackLabel } from './FieldFeedbackLabel'

const getFieldCSSClasses = (touched, errors) => {
	const classes = ['form-control', 'form-control-solid']
	if (touched && errors) {
		classes.push('is-invalid')
	}

	if (touched && !errors) {
		classes.push('is-valid')
	}

	return classes.join(' ')
}

export function Select({
	field, // { name, value, onChange, onBlur }
	form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	label,
	withFeedbackLabel = true,
	type = 'text',
	customFeedbackLabel,
	children,
	...props
}) {
	return (
		<>
			{label && <label>Select {label}</label>}
			<select
				className={getFieldCSSClasses(touched[field.name], errors[field.name])}
				{...field}
				{...props}
			>
				{children}
			</select>
			{withFeedbackLabel && (
				<FieldFeedbackLabel
					error={errors[field.name]}
					touched={touched[field.name]}
					label={label}
					customFeedbackLabel={customFeedbackLabel}
				/>
			)}
		</>
	)
}
